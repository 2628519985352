// import React from 'react';
// import { IoMdArrowDropdown } from 'react-icons/io';
// import { IoMdArrowDropright } from "react-icons/io";
// import logo from '../img/logo_editions7_transparent.png';
// import chat from '../img/chat.png';
// import Header1 from './diffuseurs/Header1';



// const Compte = () => {
//   return (
//     <div className="bg-white min-h-screen">
//       {/* Header */}
//       <Header1 />

//       <div className="container mx-auto px-4 py-8">
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
//           {/* Sidebar */}
//          <div className="bg-white shadow-md rounded-lg p-4">
// <ul className="space-y-4">
//   <li className="group">
//     <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Tableau de bord
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes livre
//     </a>
//   </li>
//   <li className="group">
//     <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Trouver des professionnels
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-informations" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
//     <span className="inline-block text-green-500 mr-2">
//       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//     </span>
// Mes Informations    </a>
//   </li>
//   <li className="group">
//     <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes publications
//     </a>
//   </li>
//   <li className="group">
//     <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Devis Impression / demande
//     </a>
//   </li>
//   <li className="group">
//     <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Conseil communication
//     </a>
//   </li>
//   <li className="group">
//     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Vente de livres
//     </a>
//   </li>
  
// </ul>


// </div>


//           {/* Main Content */}
//           <div className="md:col-span-3">
//           <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//           <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES INFORMATIONS</h2>

//               {/* Form Inputs */}
//               <form className="space-y-4">
//               <div className="grid grid-cols-1 gap-6">
//   {/* Civilité Section */}
//   <div className="flex items-center space-x-4 gap-4">
//   <label className="font-bold text-[#737373]">Civilité :</label>
//   <label className="flex items-center text-[#737373] font-semibold">
//     <input type="radio" name="civility" className="mr-2" /> Mr.
//   </label>
//   <label className="flex items-center text-[#737373] font-semibold">
//     <input type="radio" name="civility" className="mr-2" /> Mme.
//   </label>
// </div>

//   {/* Nom and Prénom Section */}
//   <div className="grid grid-cols-2 gap-4">
//   {/* Nom Section */}
//   <div className="flex items-center space-x-2">
//     <label className="font-bold text-[#737373]">Nom :</label>
//     <input type="text" className="border border-gray-400 p-2 flex-1 rounded-md  w-[21.5rem]" />
//   </div>

//   {/* Prénom Section */}
//   <div className="flex items-center space-x-2">
//     <label className="font-bold text-[#737373]">Prénom :</label>
//     <input type="text" className="border border-gray-400 p-2 flex-1 rounded-md  w-[21.5rem]" />
//   </div>
// </div>

// </div>


// <div className="grid grid-cols-3 gap-4">
//   {/* Date de naissance Section */}
//   <div className="flex items-center space-x-2">
//     <label className="font-bold whitespace-nowrap text-[#737373]">Date de naissance :</label>
//     <div className="flex items-center space-x-2">
//       <div className="relative w-28">
//         <select className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8">
//           <option>Jour</option>
//         </select>
//         <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
//       </div>
//       <div className="relative w-32">
//         <select className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8">
//           <option>Mois</option>
//         </select>
//         <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
//       </div>
//       <div className="relative w-28">
//         <select className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8">
//           <option>Année</option>
//         </select>
//         <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
//       </div>
//     </div>
//   </div>
// </div>


// <div className="flex items-center space-x-2 gap-3">
//   <label className="font-bold whitespace-nowrap text-[#737373]">Email :</label>
//   <input type="email" className="border border-gray-400 p-2 rounded-md w-[21.5rem]" /> 
// </div>



// <div className="flex items-center space-x-2 gap-4">
//   <label className="font-bold whitespace-nowrap text-[#737373]">Siret :</label>
//   <input type="text" className="border border-gray-400 p-2 rounded-md w-[21.5rem]" /> 
// </div>


// <div className="flex items-center space-x-4 gap-8">
//   <label className="font-bold text-[#737373]">TVA :</label>
//   <label className="flex items-center text-[#737373] font-semibold">
//     <input type="radio" name="tva" className="mr-2 " /> Assujetti
//   </label>
//   <label className="flex items-center ml-8 text-[#737373] font-semibold"> 
//     <input type="radio" name="tva" className="mr-2 " /> Non assujetti
//   </label>
// </div>


//               </form>
//             </div>

//             {/* Password Change Section */}
//             <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//             <h2 className="text-xl font-bold mb-4  border-b-2 border-green-500 w-3/4 pb-2">Changer mot de passe :</h2>

//   <form className="space-y-4">
//     <div className="grid grid-cols-1 gap-6">
//       <div className="flex items-center">
//         <label className="font-bold w-56 text-[#737373]">Mot de passe Actuel :</label> 
        
//         <input type="password" className="border border-gray-400 p-2 rounded-md  w-[21.5rem]" />
//       </div>
//       <div className="flex items-center">
//         <label className="font-bold w-56 text-[#737373]">Nouveau Mot de Passe :</label> 
//         <input type="password" className="border border-gray-400 p-2 rounded-md  w-[21.5rem]" />
//       </div>
//     </div>

//     <div className="flex items-center">
//       <label className="font-bold w-56 text-[#737373]">Confirmez Mot de Passe :</label> 
//       <input type="password" className="border border-gray-400 p-2 rounded-md  w-[21.5rem]" />
//     </div>
//   </form>
// </div>




//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Compte;



import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';

const Compte = () => {
  const [formData, setFormData] = useState({
    civilite: '',
    username: '',
    prenom: '',
    date_naissance: { day: '', month: '', year: '' },
    email: '',
    siret: '',
    tva: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token manquant');
        return;
      }
  
      try {
        // const response = await fetch('http://localhost:8000/api/user', {
          const response = await fetch('https://editions7.com/admin/public/api/user', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const userData = await response.json();
          console.log('Données utilisateur récupérées :', userData);
          setFormData({
            civilite: userData.civilite || '',
            username: userData.username || '',
            prenom: userData.prenom || '',
            date_naissance: userData.date_naissance || { day: '', month: '', year: '' },
            email: userData.email || '',
            siret: userData.siret || '',
            tva: userData.tva || '',
          });
        } else {
          const errorData = await response.json();
          console.error('Erreur API :', errorData);
          setErrorMessage(errorData.message || 'Erreur lors de la récupération des données.');
        }
      } catch (error) {
        console.error('Erreur réseau :', error);
        setErrorMessage('Erreur réseau. Veuillez réessayer.');
      }
    };
  
    fetchUserData();
  }, []);
  
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const payload = {
        current_password: formData.currentPassword,
        new_password: formData.newPassword,
        new_password_confirmation: formData.confirmPassword,
    };

    try {
        // const response = await fetch('http://localhost:8000/api/user/update-password', {
          const response = await fetch('https://editions7.com/admin/public/api/user/update-password', {

            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            setSuccessMessage('Mot de passe mis à jour avec succès.');
            setErrorMessage('');
        } else {
            const errorData = await response.json();
            setErrorMessage(errorData.error || 'Erreur lors de la mise à jour du mot de passe.');
        }
    } catch (error) {
        setErrorMessage('Erreur réseau. Veuillez réessayer.');
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
const handleBirthDateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
        ...prev,
        date_naissance: {
            ...prev.date_naissance,
            [name]: value,
        },
    }));
};






const handleSubmit = async (e) => {
  e.preventDefault();
  const token = localStorage.getItem('token');

  const { day, month, year } = formData.date_naissance;
  const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

  const payload = {
      civilite: formData.civilite,
      username: formData.username,
      prenom: formData.prenom,
      date_naissance: formattedDate,
      email: formData.email,
      siret: formData.siret,
      tva: formData.tva,
  };

  try {
      // const response = await fetch('http://localhost:8000/api/user/update', {
        const response = await fetch('https://editions7.com/admin/public/api/user/update', {

          method: 'PUT',
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
      });

      if (response.ok) {
          setSuccessMessage('Informations mises à jour avec succès.');
          setErrorMessage('');
      } else {
          const errorData = await response.json();
          setErrorMessage(errorData.message || 'Erreur lors de la mise à jour.');
      }
  } catch (error) {
      setErrorMessage('Erreur réseau. Veuillez réessayer.');
  }
};


  return (
    <div className="bg-white min-h-screen">
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Sidebar */}
          <div className="bg-white shadow-md rounded-lg p-4">
 <ul className="space-y-4">
   <li className="group">
     <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Tableau de bord
     </a>
   </li>
   <li className="group">
     <a href="/mes-informations" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
     <span className="inline-block text-green-500 mr-2">
       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
     </span>
 Mes Informations    </a>
   </li>
   <li className="group">
     <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Trouver des professionnels
     </a>
   </li>
   <li className="group">
     <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Mes livre
     </a>
   </li>
 
  
   <li className="group">
     <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Mes publications
     </a>
   </li>
   {/* <li className="group">
     <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
       </span>
       Devis Impression / demande
     </a>
   </li> */}
   <li className="group">
     <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Conseil communication
     </a>
   </li>
   <li className="group">
     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
       <span className="hidden group-hover:inline-block text-green-500 mr-2">
         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
       </span>
       Vente de livres
     </a>
   </li>
  
 </ul>


 </div>

          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES INFORMATIONS</h2>

              {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
              {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-6">
                  {/* Civilité Section */}
                  <div className="flex items-center space-x-4 gap-4">
                    <label className="font-bold text-[#737373]">Civilité :</label>
                    <label className="flex items-center text-[#737373] font-semibold">
                      <input type="radio" name="civilite" value="Mr" checked={formData.civilite === 'Mr'} onChange={handleInputChange} className="mr-2" /> Mr.
                    </label>
                    <label className="flex items-center text-[#737373] font-semibold">
                      <input type="radio" name="civilite" value="Mme" checked={formData.civilite === 'Mme'} onChange={handleInputChange} className="mr-2" /> Mme.
                    </label>
                  </div>

                  {/* Nom and Prénom Section */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center space-x-2">
                      <label className="font-bold text-[#737373]">Nom :</label>
                      <input type="text" name="username" value={formData.username} onChange={handleInputChange} className="border border-gray-400 p-2 flex-1 rounded-md w-[21.5rem]" />
                    </div>
                    <div className="flex items-center space-x-2">
                      <label className="font-bold text-[#737373]">Prénom :</label>
                      <input type="text" name="prenom" value={formData.prenom} onChange={handleInputChange} className="border border-gray-400 p-2 flex-1 rounded-md w-[21.5rem]" />
                    </div>
                  </div>

                  {/* Date de naissance Section */}
                  <div className="grid grid-cols-3 gap-4">
    {/* Date de naissance Section */}
    <div className="flex items-center space-x-2">
        <label className="font-bold whitespace-nowrap text-[#737373]">Date de naissance :</label>
        <div className="flex items-center space-x-2">
            {/* Jour */}
            <div className="relative w-28">
                <select
                    name="day"
                    value={formData.date_naissance.day}
                    onChange={handleBirthDateChange}
                    className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
                >
                    <option value="">Jour</option>
                    {Array.from({ length: 31 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                            {i + 1}
                        </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
                            </div>

            {/* Mois */}
            <div className="relative w-32">
                <select
                    name="month"
                    value={formData.date_naissance.month}
                    onChange={handleBirthDateChange}
                    className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
                >
                    <option value="">Mois</option>
                    {[
                        'Janvier',
                        'Février',
                        'Mars',
                        'Avril',
                        'Mai',
                        'Juin',
                        'Juillet',
                        'Août',
                        'Septembre',
                        'Octobre',
                        'Novembre',
                        'Décembre',
                    ].map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                            {month}
                        </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
            </div>

            {/* Année */}
            <div className="relative w-28">
                <select
                    name="year"
                    value={formData.date_naissance.year}
                    onChange={handleBirthDateChange}
                    className="border border-gray-400 p-1.5 rounded-md appearance-none w-full h-8"
                >
                    <option value="">Année</option>
                    {Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                <IoMdArrowDropdown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-green-500 w-9 h-9" /> {/* Larger green arrow icon */}
            </div>
        </div>
    </div>
</div>


                  {/* Email Section */}
                  <div className="flex items-center space-x-2 gap-3">
                    <label className="font-bold whitespace-nowrap text-[#737373]">Email :</label>
                    <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="border border-gray-400 p-2 rounded-md w-[21.5rem]" />
                  </div>

                  {/* Siret Section */}
                  <div className="flex items-center space-x-2 gap-4">
                    <label className="font-bold whitespace-nowrap text-[#737373]">Siret :</label>
                    <input type="number" name="siret" value={formData.siret} onChange={handleInputChange} className="border border-gray-400 p-2 rounded-md w-[21.5rem]" />
                  </div>

                  {/* TVA Section */}
                 {/* TVA Section */}
<div className="flex items-center space-x-4 gap-8">
  <label className="font-bold text-[#737373]">TVA :</label>
  <label className="flex items-center text-[#737373] font-semibold">
    <input
      type="radio"
      name="tva"
      value="assujetti" 
      checked={formData.tva === 'assujetti'}
      onChange={handleInputChange}
      className="mr-2"
    /> Assujetti
  </label>
  <label className="flex items-center ml-8 text-[#737373] font-semibold">
    <input
      type="radio"
      name="tva"
      value="non assujetti"
      checked={formData.tva === 'non assujetti'}
      onChange={handleInputChange}
      className="mr-2"
    /> Non assujetti
  </label>
</div>

                </div>

                <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
                  Enregistrer
                </button>
              </form>
            </div>

            {/* Password Change Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
    <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Changer mot de passe :</h2>
    {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
    {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
    <form className="space-y-4" onSubmit={handlePasswordChange}>
        <div className="grid grid-cols-1 gap-6">
            <div className="flex items-center">
                <label className="font-bold w-56 text-[#737373]">Mot de passe actuel :</label>
                <input
                    type="password"
                    name="currentPassword"
                    value={formData.currentPassword}
                    onChange={handleInputChange}
                    className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
                />
            </div>
            <div className="flex items-center">
                <label className="font-bold w-56 text-[#737373]">Nouveau mot de passe :</label>
                <input
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleInputChange}
                    className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
                />
            </div>
            <div className="flex items-center">
                <label className="font-bold w-56 text-[#737373]">Confirmez mot de passe :</label>
                <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    className="border border-gray-400 p-2 rounded-md w-[21.5rem]"
                />
            </div>
        </div>
        <button type="submit" className="bg-green-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
            Changer
        </button>
    </form>
</div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Compte;
