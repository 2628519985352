import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateField, resetFields } from '../InscriptionSlice'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';
import stripe from '../img/logo-stripe.png';
import { IoMdEye } from 'react-icons/io'; // Eye icon for show

const InscriptionSuite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inscriptionData = useSelector((state) => state.inscription);

  const [fileName, setFileName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour afficher/masquer le modal

  // Gestion des changements dans les champs de texte
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ field: name, value }));
  };

  // Gestion du changement de fichier
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      dispatch(updateField({ field: 'photo_profil', value: file }));
    }
  };

  // Gestion de la soumission
  const handleSubmit = async () => {
    const formData = new FormData();
    Object.entries(inscriptionData).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });
  
    // Vérifiez les données avant l'envoi
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  
    try {
      // const response = await fetch('http://localhost:8000/api/register', {
        const response = await fetch('https://editions7.com/admin/public/api/register', {

        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Données enregistrées :', data);
        setIsModalOpen(true); // Afficher le modal de succès
      } else {
        const error = await response.json();
        console.error('Erreur API:', error);
        alert(`Erreur lors de l'inscription : ${JSON.stringify(error.errors)}`);
      }
    } catch (err) {
      console.error('Erreur réseau ou autre:', err);
      alert('Une erreur réseau est survenue. Veuillez réessayer.');
    }
  };
  

  // Gestion de la fermeture du modal et redirection
  const handleCloseModal = () => {
    dispatch(resetFields()); // Réinitialiser les champs du formulaire
    setIsModalOpen(false); // Fermer le modal
    navigate(-1); // Revenir à la page précédente
  };

  return (
    <div className="bg-white py-10 px-4 md:px-0 md:max-w-4xl mx-auto">
      <h1 className="text-center text-3xl font-bold mb-8">Complétez votre inscription</h1>

      <div className="space-y-6">
        {/* Photo de profil */}
        <div className="flex items-center space-x-4">
          <label className="text-lg font-bold">VOTRE PHOTO DE PROFIL :</label>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
          />
          <button
            className="bg-[#38dcaa] text-white py-2 px-4 hover:bg-[#38dcaa] cursor-pointer"
            onClick={() => document.getElementById('fileInput').click()}
          >
            Choisissez un fichier
          </button>
          {fileName && <span className="text-gray-700 italic">{fileName}</span>}
        </div>

        {/* Adresse de facturation */}
        <input
          type="text"
          name="adresse_facturation"
          placeholder="VOTRE ADRESSE DE FACTURATION"
          value={inscriptionData.adresse_facturation || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Commune */}
        <input
          type="text"
          name="commune"
          placeholder="COMMUNE"
          value={inscriptionData.commune || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Code postal */}
        <input
          type="text"
          name="code_postal"
          placeholder="CODE POSTAL"
          value={inscriptionData.code_postal || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Téléphone */}
        <input
          type="text"
          name="telephone"
          placeholder="TELEPHONE"
          value={inscriptionData.telephone || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Site internet */}
        <input
          type="text"
          name="site_internet"
          placeholder="SITE INTERNET"
          value={inscriptionData.site_internet || ''}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        {/* Réglez votre adhésion */}
        <div>
          <p className="font-bold mb-4">REGLEZ VOTRE ADHESION :</p>
          <p className="italic text-sm font-semibold">
            L'abonnement à la plateforme est à 29 € + 10 € par métier complémentaire, vous serez 
            <br />
            redirigé sur notre plateforme de règlement STRIPE
          </p>
          <img
            src={stripe}
            alt="Stripe Payment"
            className="w-1/2 mx-auto my-4"
          />
        </div>

        {/* Boutons */}
        <div className="flex justify-between mt-8">
          <button
            className="bg-red-600 text-white font-bold py-4 px-12 rounded-lg hover:bg-red-500"
            onClick={() => navigate(-1)}
          >
            RETOUR
          </button>
          <button
            className="bg-green-600 text-white font-bold py-4 px-12 rounded-lg hover:bg-green-500"
            onClick={handleSubmit}
          >
            S'INSCRIRE
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center w-96">
            <h2 className="text-2xl font-bold mb-4">Inscription réussie !</h2>
            <p className="text-lg mb-6">
              Votre inscription a été enregistrée avec succès.
            </p>
            <button
              className="bg-green-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-green-500"
              onClick={handleCloseModal}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InscriptionSuite;
