import React, { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '../InscriptionSlice'; // Assurez-vous que le chemin est correct

const Inscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inscriptionData = useSelector((state) => state.inscription);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [missingFields, setMissingFields] = useState([]); // Stockage des champs manquants
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour afficher le modal

  // Gestion des changements de champs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ field: name, value }));
  };

  // Validation des champs obligatoires
  const validateFields = () => {
    const requiredFields = [
      { name: 'username', label: 'Nom' },
      { name: 'email', label: 'Email' },
      { name: 'identifiant', label: 'Identifiant' },
      { name: 'password', label: 'Mot de passe' },
      { name: 'password_confirmation', label: 'Confirmation du mot de passe' },
      { name: 'role', label: 'Rôle' },
    ];

    const missing = requiredFields.filter(
      (field) => !inscriptionData[field.name]
    );

    // Validation du mot de passe
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (
      inscriptionData.password &&
      !passwordRegex.test(inscriptionData.password)
    ) {
      missing.push({
        name: 'password',
        label:
          'Mot de passe (Doit contenir au moins 8 caractères, une majuscule et un chiffre)',
      });
    }

    // Vérification des mots de passe identiques
    if (
      inscriptionData.password &&
      inscriptionData.password_confirmation &&
      inscriptionData.password !== inscriptionData.password_confirmation
    ) {
      missing.push({
        name: 'password_confirmation',
        label: 'Les mots de passe ne correspondent pas',
      });
    }

    return missing;
  };

  // Gestion du clic sur "Suivant"
  const handleSuivantClick = () => {
    const missing = validateFields();
    if (missing.length > 0) {
      setMissingFields(missing);
      setIsModalOpen(true);
    } else {
      navigate('/inscriptionsuite');
    }
  };

  return (
    <div className="bg-white py-10 px-4 md:px-0 md:max-w-4xl mx-auto">
      <h1 className="text-center text-3xl font-bold mb-8">INSCRIPTION</h1>
      
      <div className="space-y-6">
        <input
          type="text"
          name="username"
          placeholder="VOTRE NOM"
          value={inscriptionData.username}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="email"
          name="email"
          placeholder="VOTRE EMAIL"
          value={inscriptionData.email}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />
        <input
          type="text"
          name="identifiant"
          placeholder="CREEZ UN IDENTIFIANT"
          value={inscriptionData.identifiant}
          onChange={handleInputChange}
          className="w-full border border-gray-400 p-4 rounded-lg italic"
        />

        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="CREEZ UN MOT DE PASSE"
            value={inscriptionData.password}
            onChange={handleInputChange}
            className="w-full border border-gray-400 p-4 rounded-lg italic"
          />
          <p className="absolute right-28 top-4 text-sm text-red-600">
            (8 Caractères minimum avec au moins une majuscule et un numéro)
          </p>
          <span
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-4 top-4 cursor-pointer"
          >
            {showPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>

        <div className="relative">
  <input
    type={showConfirmPassword ? 'text' : 'password'}
    name="password_confirmation" // Changez le name ici
    placeholder="CONFIRMER VOTRE MOT DE PASSE"
    value={inscriptionData.password_confirmation || ''} // Ajoutez cette ligne
    onChange={handleInputChange}
    className="w-full border border-gray-400 p-4 rounded-lg italic"
  />
  <span
    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
    className="absolute right-4 top-4 cursor-pointer"
  >
    {showConfirmPassword ? '👁️' : '👁️‍🗨️'}
  </span>
</div>


        <div className="relative flex items-center space-x-4">
          <label className="font-semibold italic text-lg w-[30rem]">
            VOUS VOUS INSCRIVEZ EN TANT QUE :
          </label>
          <div className="relative w-4/5">
            <select
              name="role"
              value={inscriptionData.role}
              onChange={handleInputChange}
              className="appearance-none border border-gray-400 p-4 rounded-lg italic w-full"
            >
              <option value="AUTEUR">AUTEUR</option>
              <option value="DIFFUSEUR">DIFFUSEUR</option>
            </select>
            <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
              <IoMdArrowDropdown className="w-12 h-12 text-green-500" />
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <label className="block flex items-center">
            <input type="checkbox" className="mr-2 w-6 h-6" />
            <span className="italic font-semibold">
              OUI, j'accepte les <a href="#" className="text-blue-600 hover:underline">conditions générales d'utilisation de la plateforme</a>
            </span>
          </label>
          <label className="block flex items-center">
            <input type="checkbox" className="mr-2 w-6 h-6" />
            <span className="italic font-semibold">
              OUI, j'accepte la <a href="#" className="text-blue-600 hover:underline">politique de confidentialité</a>
            </span>
          </label>
          <label className="block flex items-center">
            <input type="checkbox" className="mr-2 w-6 h-6" />
            <span className="italic font-semibold">
              OUI, j'accepte le <a href="#" className="text-blue-600 hover:underline">contrat d'édition</a>
            </span>
          </label>
        </div>

        <div className="flex justify-between items-center">
          <p className="text-lg font-bold">
            L'abonnement à la plateforme est à 29 C.<br />
            Ajouter 10 C par métier.
          </p>
          <button
            onClick={handleSuivantClick}
            className="bg-green-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-green-500 text-2xl"
          >
            SUIVANT
          </button>
        </div>
      </div>
        {/* Modal pour champs manquants */}
        {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center w-96">
            <h2 className="text-2xl font-bold mb-4">Champs manquants</h2>
            <ul className="text-left text-red-600">
              {missingFields.map((field, index) => (
                <li key={index}>{field.label}</li>
              ))}
            </ul>
            <button
              className="bg-red-600 text-white font-bold py-2 px-8 rounded-lg hover:bg-red-500 mt-4"
              onClick={() => setIsModalOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Inscription;
