// import React, { useState } from 'react';
// import { IoMdCreate, IoMdTrash, IoMdArrowDropright } from 'react-icons/io';
// import logo from '../img/logo_editions7_transparent.png';
// import chat from '../img/chat.png';
// import Header1 from './diffuseurs/Header1';

// const Livre = () => {
//     const [showModal, setShowModal] = useState(false); // État pour afficher le modal d'ajout
//     const [showEditModal, setShowEditModal] = useState(false); // État pour afficher le modal d'édition
//     const [selectedBook, setSelectedBook] = useState({}); // Stocke les données du livre à éditer

//     const bookData = {
//         title: "Mon Livre",
//         description: "Ceci est la description du livre.",
//         price: "20€",
//         salesTarget: "500 exemplaires",
//         coverImage: "Aucune image sélectionnée",
//     };

//     // Affiche le modal d'ajout de livre
//     const handleAddBook = () => {
//         setShowModal(true);
//     };

//     // Affiche le modal d'édition avec les données du livre sélectionné
//     const handleEditBook = (book) => {
//         setSelectedBook(book); // Stocke les données du livre sélectionné
//         setShowEditModal(true); // Ouvre le modal d'édition
//     };

//     const handleSubmitBook = (event) => {
//         event.preventDefault();
//         // Logique pour soumettre le formulaire d'ajout
//         alert("Livre ajouté avec succès !");
//         setShowModal(false); // Fermer le modal après la soumission
//     };

//     const handleSubmitEditBook = (event) => {
//         event.preventDefault();
//         // Logique pour soumettre les modifications
//         alert("Livre modifié avec succès !");
//         setShowEditModal(false); // Fermer le modal après la soumission
//     };

//     const handleCloseModal = () => {
//         setShowModal(false); // Fermer le modal d'ajout
//     };

//     const handleCloseEditModal = () => {
//         setShowEditModal(false); // Fermer le modal d'édition
//     };

//     return (
//         <div className="bg-white min-h-screen">
//             {/* Header */}
//             <Header1 />

//             <div className="container mx-auto px-4 py-8">
//                 <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
//                          {/* Sidebar */}
//          <div className="bg-white shadow-md rounded-lg p-4">
// <ul className="space-y-4">
//   <li className="group">
//     <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Tableau de bord
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-livres" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
//     <span className="inline-block text-green-500 mr-2">
//       <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
//     </span>
//       Mes livre
//     </a>
//   </li>
//   <li className="group">
//     <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Trouver des professionnels
//     </a>
//   </li>
//   <li className="group">
//     <a href="/mes-informations" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
// Mes Informations    </a>
//   </li>
//   <li className="group">
//     <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Mes publications
//     </a>
//   </li>
//   <li className="group">
//     <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Devis Impression / demande
//     </a>
//   </li>
//   <li className="group">
//     <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Conseil communication
//     </a>
//   </li>
//   <li className="group">
//     <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
//       <span className="hidden group-hover:inline-block text-green-500 mr-2">
//         <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
//       </span>
//       Vente de livres
//     </a>
//   </li>
  
// </ul>


// </div>

//                     {/* Main Content */}
//                     <div className="md:col-span-3">
//                         <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
//                             <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">MES LIVRES</h2>

//                             {/* Bouton Ajouter un livre */}
//                             <div className="flex justify-end mb-4">
//                                 <button
//                                     className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
//                                     onClick={handleAddBook}
//                                 >
//                                     Ajouter un livre
//                                 </button>
//                             </div>

//                             {/* Tableau affichant les données du livre */}
//                             <table className="table-auto border-collapse border border-gray-400 w-full">
//                                 <thead>
//                                     <tr className="bg-gray-200">
//                                         <th className="border border-gray-300 p-2">Nom du livre</th>
//                                         <th className="border border-gray-300 p-2">Description</th>
//                                         <th className="border border-gray-300 p-2">Prix</th>
//                                         <th className="border border-gray-300 p-2">Objectif de vente</th>
//                                         <th className="border border-gray-300 p-2">Couverture</th>
//                                         <th className="border border-gray-300 p-2">Actions</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr>
//                                         <td className="border border-gray-300 p-2">{bookData.title}</td>
//                                         <td className="border border-gray-300 p-2">{bookData.description}</td>
//                                         <td className="border border-gray-300 p-2">{bookData.price}</td>
//                                         <td className="border border-gray-300 p-2">{bookData.salesTarget}</td>
//                                         <td className="border border-gray-300 p-2">{bookData.coverImage}</td>
//                                         <td className="border border-gray-300 p-2 flex space-x-2">
//                                             <button
//                                                 className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 flex items-center"
//                                                 onClick={() => handleEditBook(bookData)} // Ouvre le modal d'édition avec les données du livre
//                                             >
//                                                 <IoMdCreate className="mr-1" />
//                                                 Modifier
//                                             </button>
//                                             <button
//                                                 className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 flex items-center"
//                                                 onClick={() => alert('Supprimer le livre')}
//                                             >
//                                                 <IoMdTrash className="mr-1" />
//                                                 Supprimer
//                                             </button>
//                                         </td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Modal d'ajout */}
//             {showModal && (
//                 <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//                     <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
//                         <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">Ajouter un nouveau livre</h2>
//                         <form onSubmit={handleSubmitBook}>
//                             <div className="mb-4">
//                                 <label className="block font-bold mb-2 text-[#737373]">Nom du livre :</label>
//                                 <input
//                                     type="text"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     placeholder="Entrez le nom du livre"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Description :</label>
//                                 <textarea
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     placeholder="Entrez la description du livre"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Prix :</label>
//                                 <input
//                                     type="number"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     placeholder="Entrez le prix du livre"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Objectif de vente sur 1 an :</label>
//                                 <input
//                                     type="text"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     placeholder="Entrez l'objectif de vente"
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Couverture (1ère et 4ème) :</label>
//                                 <input
//                                     type="file"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     accept="image/*"
//                                     required
//                                 />
//                             </div>
//                             <div className="flex justify-end space-x-4">
//                                 <button
//                                     type="button"
//                                     className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
//                                     onClick={handleCloseModal}
//                                 >
//                                     Annuler
//                                 </button>
//                                 <button
//                                     type="submit"
//                                     className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
//                                 >
//                                     Ajouter
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             )}

//             {/* Modal d'édition */}
//             {showEditModal && (
//                 <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//                     <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
//                         <h2 className="text-xl font-bold mb-4">Modifier le livre</h2>
//                         <form onSubmit={handleSubmitEditBook}>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Nom du livre :</label>
//                                 <input
//                                     type="text"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     defaultValue={selectedBook.title} // Valeur par défaut pré-remplie
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Description :</label>
//                                 <textarea
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     defaultValue={selectedBook.description} 
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Prix :</label>
//                                 <input
//                                     type="number"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     defaultValue={selectedBook.price} 
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Objectif de vente sur 1 an :</label>
//                                 <input
//                                     type="text"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     defaultValue={selectedBook.salesTarget} 
//                                     required
//                                 />
//                             </div>
//                             <div className="mb-4">
//                                 <label className="block text-[#737373] font-bold mb-2">Couverture (1ère et 4ème) :</label>
//                                 <input
//                                     type="file"
//                                     className="border border-gray-300 p-2 w-full rounded-md"
//                                     accept="image/*"
//                                 />
//                             </div>
//                             <div className="flex justify-end space-x-4">
//                                 <button
//                                     type="button"
//                                     className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
//                                     onClick={handleCloseEditModal}
//                                 >
//                                     Annuler
//                                 </button>
//                                 <button
//                                     type="submit"
//                                     className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
//                                 >
//                                     Modifier
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Livre;


import React, { useState, useEffect } from 'react';
import { IoMdCreate, IoMdTrash, IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';
import axios from 'axios';

const Livre = () => {
    const [livres, setLivres] = useState([]); // Stocke les livres
    const [error, setError] = useState(''); // Stocke les erreurs éventuelles
    const [showModal, setShowModal] = useState(false); // État pour afficher le modal d'ajout
    const [showEditModal, setShowEditModal] = useState(false); // État pour afficher le modal d'édition
    const [selectedBook, setSelectedBook] = useState({}); // Stocke les données du livre à éditer
    const [newBook, setNewBook] = useState({}); // Données du nouveau livre
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const rowsPerPage = 5; // Number of rows per page
    const [showSuccessModal, setShowSuccessModal] = useState(false); // For success message modal
    const [bookToDelete, setBookToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    // Fonction pour récupérer les livres depuis l'API
    const fetchLivresDiffuseur = async () => {
        setError('');
        try {
            // const response = await fetch('http://localhost:8000/api/livres-Auteur', {
                const response = await fetch('https://editions7.com/admin/public/api/livres-Auteur', {

                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des livres.');
            }

            const data = await response.json();
            setLivres(data);
        } catch (error) {
            setError(error.message);
        }
    };

    // Utiliser useEffect pour récupérer les livres au chargement du composant
    useEffect(() => {
        fetchLivresDiffuseur();
    }, []);

    // Handle pagination logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = livres.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(livres.length / rowsPerPage);

    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    // Modal functions
    const handleAddBook = () => {
        setShowModal(true);
    };

    const handleEditBook = (book) => {
        setSelectedBook(book);
        setShowEditModal(true);
    };

    const handleSubmitBook = async (event) => {
        if (event) event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('title', newBook.title);
            formData.append('description', newBook.description);
            formData.append('price', newBook.price);
            formData.append('sales_target', newBook.sales_target);

            if (newBook.cover_images && newBook.cover_images.length > 0) {
                newBook.cover_images.forEach((file) =>
                    formData.append('cover_images[]', file)
                );
            }

            // const response = await fetch('http://localhost:8000/api/livres-store', {
                const response = await fetch('https://editions7.com/admin/public/api/livres-store', {

                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });

            if (!response.ok) throw new Error('Erreur lors de l\'ajout du livre.');

            const data = await response.json();
            setLivres([...livres, data]);
            setShowModal(false);
            setNewBook({});
        } catch (error) {
            setError(error.message);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        localStorage.setItem('currentPage', pageNumber); // Save the current page in localStorage
    };
    const handleDeleteBook = async () => {
        if (!bookToDelete) return;

        try {
            // await axios.delete(`http://localhost:8000/api/supprimer-livres/${bookToDelete.id}`, {
                await axios.delete(`https://editions7.com/admin/public/api/supprimer-livres/${bookToDelete.id}`, {

                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            setShowDeleteModal(false);
            setBookToDelete(null);

            // Refresh the book list
            fetchLivresDiffuseur();
        } catch (error) {
            console.error('Erreur lors de la suppression du livre:', error.response || error.message);
            alert('Erreur lors de la suppression du livre');
        }
    };

    // const handleEditBook = (book) => {
    //     setSelectedBook(book);
    //     setShowEditModal(true);
    // };

    const handleSubmitEditBook = async (event) => {
        event.preventDefault();

        try {
            const jsonPayload = {
                title: selectedBook.title,
                description: selectedBook.description,
                price: selectedBook.price,
                sales_target: selectedBook.sales_target,
            };

            // Update book details
            await axios.put(
                // `http://localhost:8000/api/livres-modifier/${selectedBook.id}`,
                `https://editions7.com/admin/public/api/livres-modifier/${selectedBook.id}`,

                jsonPayload,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // If cover images are selected, upload them
            if (selectedBook.cover_images && selectedBook.cover_images.length > 0) {
                const formData = new FormData();
                selectedBook.cover_images.forEach((file) => {
                    if (file instanceof File) {
                        formData.append('cover_images[]', file);
                    }
                });

                await axios.post(
                    // `http://localhost:8000/api/livres-upload-images/${selectedBook.id}`,
                    `https://editions7.com/admin/public/api/livres-upload-images/${selectedBook.id}`,

                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }

            setShowEditModal(false);
            setShowSuccessModal(true);

            // Close the success modal and refresh after 3 seconds
            setTimeout(() => {
                setShowSuccessModal(false);
                fetchLivresDiffuseur(); // Refresh the list without full page reload
            }, 3000);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du livre:', error.response || error.message);
            alert('Erreur lors de la mise à jour des informations');
        }
    };

    const handleCloseEditModal = () => setShowEditModal(false);

    return (
        <div className="bg-white min-h-screen">
            <Header1 />
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {/* Sidebar */}
         <div className="bg-white shadow-md rounded-lg p-4">
<ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livre
    </a>
  </li>

 
  <li className="group">
    <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes publications
    </a>
  </li>
  {/* <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Devis Impression / demande
    </a>
  </li> */}
  <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Conseil communication
    </a>
  </li>
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
</ul>


</div>

                    {/* Main Content */}
                    <div className="md:col-span-3">
                        <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
                            <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">
                                MES LIVRES
                            </h2>

                            <div className="flex justify-end mb-4">
                                <button
                                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                    onClick={handleAddBook}
                                >
                                    Ajouter un livre
                                </button>
                            </div>

                            <table className="table-auto border-collapse border border-gray-400 w-full">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 p-2">Nom du livre</th>
                                        <th className="border border-gray-300 p-2">Description</th>
                                        <th className="border border-gray-300 p-2">Prix</th>
                                        <th className="border border-gray-300 p-2">Objectif de vente</th>
                                        <th className="border border-gray-300 p-2">Couverture</th>
                                        <th className="border border-gray-300 p-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRows.map((livre) => (
                                        <tr key={livre.id}>
                                            <td className="border border-gray-300 p-2">{livre.title}</td>
                                            <td className="border border-gray-300 p-2">{livre.description}</td>
                                            <td className="border border-gray-300 p-2">{livre.price}€</td>
                                            <td className="border border-gray-300 p-2">{livre.sales_target}</td>
                                            <td className="p-2 border border-gray-300">
                                                <div className="flex space-x-2">
                                                    {livre.cover_images &&
                                                    Array.isArray(livre.cover_images) &&
                                                    livre.cover_images.length > 0 ? (
                                                        livre.cover_images.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image}
                                                                alt={`Couverture ${index + 1}`}
                                                                className="w-16 h-16 object-cover rounded shadow-md"
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="text-gray-500 italic">
                                                            Aucune couverture disponible
                                                        </p>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="border border-gray-300 p-2 flex space-x-2">
                                                <button
                                                    className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 flex items-center"
                                                    onClick={() => handleEditBook(livre)}
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600"
                                                    onClick={() => {
                                                        setBookToDelete(livre);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    Supprimer
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination */}
                            <div className="flex justify-center mt-4">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePageChange(index + 1)}
                                        className={`px-3 py-1 border rounded ${
                                            currentPage === index + 1
                                                ? 'bg-green-500 text-white'
                                                : 'bg-white text-gray-700'
                                        }`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             {/* Modal d'ajout */}
{showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl font-bold mb-4">Ajouter un nouveau livre</h2>
            <form
                onSubmit={(e) => {
                    handleSubmitBook(e);
                }}
            >
                <div className="mb-4">
                    <label className="block font-bold mb-2">Nom du livre :</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.title || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, title: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2">Description :</label>
                    <textarea
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.description || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, description: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2">Prix :</label>
                    <input
                        type="number"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.price || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, price: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2">Objectif de vente :</label>
                    <input
                        type="number"
                        className="border border-gray-300 p-2 w-full"
                        value={newBook.sales_target || ''}
                        onChange={(e) =>
                            setNewBook({ ...newBook, sales_target: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
    <label className="block font-bold mb-2">1ère et 4ème de couverture :</label>
    <input
        type="file"
        className="border border-gray-300 p-2 w-full"
        accept="image/*"
        multiple
        onChange={(e) => {
            const newFiles = Array.from(e.target.files);

            // Concatène les nouveaux fichiers avec ceux existants
            const updatedFiles = [...(newBook.cover_images || []), ...newFiles];

            // Met à jour l'état
            setNewBook({
                ...newBook,
                cover_images: updatedFiles,
                previewUrls: updatedFiles.map((file) => URL.createObjectURL(file)),
            });
        }}
    />

    {/* Afficher les fichiers sélectionnés */}
    {newBook.cover_images && newBook.cover_images.length > 0 && (
        <div className="mt-4">
            <h3 className="font-bold mb-2">Fichiers sélectionnés :</h3>
            <ul className="list-disc pl-5">
                {newBook.cover_images.map((file, index) => (
                    <li key={index} className="text-gray-700">
                        {file.name}
                    </li>
                ))}
            </ul>
        </div>
    )}

    {/* Prévisualisation des fichiers groupés */}
    {newBook.previewUrls && newBook.previewUrls.length > 0 && (
        <div className="mt-4 grid grid-cols-2 gap-4">
            {newBook.previewUrls.map((url, index) => (
                <div
                    key={index}
                    className="border border-gray-300 rounded-lg overflow-hidden shadow-md"
                >
                    <img
                        src={url}
                        alt={`Preview ${index}`}
                        className="w-full h-32 object-cover"
                    />
                </div>
            ))}
        </div>
    )}
</div>


                <div className="flex justify-end space-x-4">
                    <button
                        type="submit"
                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                        onClick={() => setShowModal(false)}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                    >
                        Ajouter
                    </button>
                </div>
            </form>
        </div>
    </div>
)}
 {/* Success Modal */}
 {showSuccessModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h2 className="text-green-500 text-xl font-bold mb-4">Succès</h2>
                        <p className="text-gray-700">Livre modifié avec succès!</p>
                    </div>
                </div>
            )}

{showEditModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-xl font-bold mb-4 pb-2 border-b-2 border-green-500 w-3/4">
                Modifier le livre
            </h2>
            <form onSubmit={handleSubmitEditBook}>
                <div className="mb-4">
                    <label className="block font-bold mb-2 text-[#737373]">Nom du livre :</label>
                    <input
                        type="text"
                        className="border border-gray-300 p-2 w-full rounded-md"
                        value={selectedBook.title || ''}
                        onChange={(e) =>
                            setSelectedBook({ ...selectedBook, title: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2 text-[#737373]">Description :</label>
                    <textarea
                        className="border border-gray-300 p-2 w-full rounded-md"
                        value={selectedBook.description || ''}
                        onChange={(e) =>
                            setSelectedBook({ ...selectedBook, description: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2 text-[#737373]">Prix :</label>
                    <input
                        type="number"
                        className="border border-gray-300 p-2 w-full rounded-md"
                        value={selectedBook.price || ''}
                        onChange={(e) =>
                            setSelectedBook({ ...selectedBook, price: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2 text-[#737373]">
                        Objectif de vente sur 1 an :
                    </label>
                    <input
                        type="number"
                        className="border border-gray-300 p-2 w-full rounded-md"
                        value={selectedBook.sales_target || ''}
                        onChange={(e) =>
                            setSelectedBook({ ...selectedBook, sales_target: e.target.value })
                        }
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block font-bold mb-2 text-[#737373]">
                        Couverture (1ère et 4ème) :
                    </label>
                    <input
                        type="file"
                        className="border border-gray-300 p-2 w-full rounded-md"
                        accept="image/*"
                        multiple
                        onChange={(e) => {
                            const files = Array.from(e.target.files);
                            setSelectedBook((prev) => ({
                                ...prev,
                                cover_images: [...(prev.cover_images || []), ...files],
                                previewUrls: [
                                    ...(prev.previewUrls || []),
                                    ...files.map((file) => URL.createObjectURL(file)),
                                ],
                            }));
                        }}
                    />
                </div>

                {/* Display all selected files */}
                {selectedBook.previewUrls && selectedBook.previewUrls.length > 0 && (
    <div className="mt-4 grid grid-cols-3 gap-4">
        {selectedBook.previewUrls.map((url, index) => (
            <div
                key={index}
                className="border border-gray-300 rounded-lg overflow-hidden shadow-md"
            >
                <img
                    src={url}
                    alt={`Preview ${index}`}
                    className="w-full h-32 object-cover"
                />
            </div>
        ))}
    </div>
)}

                <div className="flex justify-end space-x-4 mt-6">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                        onClick={handleCloseEditModal}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                    >
                        Modifier
                    </button>
                </div>
            </form>
        </div>
    </div>
)}

 {/* Delete Modal */}
 {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h2 className="text-red-500 text-xl font-bold mb-4">Confirmer la suppression</h2>
                        <p className="text-gray-700 mb-6">
                            Êtes-vous sûr de vouloir supprimer le livre <strong>{bookToDelete?.title}</strong> ?
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                onClick={() => setShowDeleteModal(false)}
                            >
                                Annuler
                            </button>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                                onClick={handleDeleteBook}
                            >
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Livre;
