import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  email: '',
  password: '',
  password_confirmation: '',
  role: '',
  photo_profil: null,
  adresse_facturation: '',
  commune: '',
  code_postal: '',
  telephone: '',
  site_internet: '',
  identifiant: '',
  setEmailOrIdentifiant:'',
};

const inscriptionSlice = createSlice({
  name: 'inscription',
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetFields: () => initialState, // Réinitialiser tous les champs
  },
});

export const { updateField, resetFields } = inscriptionSlice.actions;
export default inscriptionSlice.reducer;

