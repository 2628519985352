import React, { useState, useEffect } from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './diffuseurs/Header1';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [username, setUsername] = useState(''); // Dynamically store the username
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        if (!token) {
          navigate('/login'); // Redirect to login if no token is found
          return;
        }

        // const response = await fetch('http://localhost:8000/api/user', {
          const response = await fetch('https://editions7.com/admin/public/api/user', {

          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsername(data.username); // Set the username from the API response
        } else {
          navigate('/login'); // Redirect to login if unauthorized
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        navigate('/login');
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleLogout = () => {
    // Clear localStorage and navigate to login page
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/login');
  };

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <Header1 />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
       {/* Sidebar */}
          <div className="bg-white shadow-md rounded-lg p-4">
 <ul className="space-y-4">
  <li className="group">
    <a href="/mon-compte" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Tableau de bord
    </a>
  </li>
  <li className="group">
    <a href="/mes-informations" className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center">
    <span className="inline-block text-green-500 mr-2">
      <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
    </span>
Mes Informations    </a>
  </li>
  <li className="group">
    <a href="/recherchre-diffuseurs" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Trouver des professionnels
    </a>
  </li>
  <li className="group">
    <a href="/mes-livres" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes livre
    </a>
  </li>


  <li className="group">
    <a href="/mes-publications" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Mes publications
    </a>
  </li>
  {/* <li className="group">
    <a href="/devis" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> 
      </span>
      Devis Impression / demande
    </a>
  </li> */}
  <li className="group">
    <a href="/communication" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Conseil communication
    </a>
  </li>
  <li className="group">
    <a href="/book-sales" className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center">
      <span className="hidden group-hover:inline-block text-green-500 mr-2">
        <IoMdArrowDropright className="text-green-500 h-6 w-6" /> {/* Larger green icon */}
      </span>
      Vente de livres
    </a>
  </li>
  
 </ul>


</div>
          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              {/* Welcome Message */}
              <p className="text-lg mb-4">
                Bonjour <strong>{username || 'Utilisateur'}</strong> (vous n'êtes pas{' '}
                <strong>{username || 'Utilisateur'}</strong>?{' '}
                <span onClick={handleLogout} className="text-blue-600 cursor-pointer">
                  Déconnexion
                </span>
                )
              </p>

              {/* Introduction */}
              <p className="text-sm mb-4">
                À partir du tableau de bord de votre compte, vous pouvez visualiser vos{' '}
                <a href="/recent-orders" className="text-blue-600">
                  commandes récentes
                </a>
                , gérer vos{' '}
                <a href="/delivery-addresses" className="text-blue-600">
                  adresses de livraison et de facturation
                </a>{' '}
                ainsi que{' '}
                <a href="/change-password" className="text-blue-600">
                  changer votre mot de passe
                </a>{' '}
                et{' '}
                <a href="/account-details" className="text-blue-600">
                  les détails de votre compte
                </a>
                .
              </p>

              {/* Main Section */}
              <h3 className="text-md font-bold mb-4">
                Pour commencer votre projet d'édition de livre, je vous conseille :
              </h3>
              <ol className="list-decimal pl-4 space-y-4">
                <li>
                  Déterminer le coût d'impression de votre ouvrage :
                  <ul className="list-disc pl-6">
                    <li>Cliquer sur "demander un devis d'impression"</li>
                  </ul>
                </li>
                <li>
                  Si vous avez des besoins de correction orthographique, mise en page, illustration, relation presse :
                  <ul className="list-disc pl-6">
                    <li>Cliquer sur "Trouver des professionnels"</li>
                  </ul>
                </li>
                <li>
                  Trouver des diffuseurs pour vendre vos livres dans les points de vente :
                  <ul className="list-disc pl-6">
                    <li>Cliquer sur "Trouver des professionnels"</li>
                  </ul>
                </li>
                <li>
                  À tout moment, vous pouvez échanger avec les membres de la plateforme :
                  <ul className="list-disc pl-6">
                    <li>Cliquer sur "Discuter avec les membres"</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
