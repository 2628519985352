import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo_editions7_transparent.png';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in and their role when the component mounts
    const userLoggedIn = localStorage.getItem('isLoggedIn');
    const role = localStorage.getItem('userRole');
    if (userLoggedIn) {
      setIsLoggedIn(true);
      setUserRole(role); // Set the user's role
    }
  }, []);

  const handleLogout = () => {
    // Remove the login state and user role from localStorage and update state
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userRole');
    setIsLoggedIn(false);
    navigate('/login'); // Redirect to the login page after logout
  };

  return (
    <header className="bg-[#00ae14] py-4 px-5 md:px-10 flex justify-between items-center">
      {/* Logo on the left */}
      <div className="flex items-center">
        <a href="/Acceuil">
          <img src={logo} alt="Logo Editions 7" className="w-32 md:w-48 lg:w-[24rem] h-auto" />
        </a>
      </div>

      {/* Responsive Navigation */}
      <nav>
        <ul className="flex space-x-4 md:space-x-6 lg:space-x-8 text-white text-base md:text-lg lg:text-xl font-semibold">
          <li>
            <a href="/Acceuil" className="hover:bg-[#26d43a] px-3 md:px-4 py-2 rounded-md transition duration-300 ease-in-out">
              Accueil
            </a>
          </li>
          <li>
            <a href="/commencez-ici" className="hover:bg-[#26d43a] px-3 md:px-4 py-2 rounded-md transition duration-300 ease-in-out">
              Commencez Ici
            </a>
          </li>
          <li>
            <a href="/fonctionnement" className="hover:bg-[#26d43a] px-3 md:px-4 py-2 rounded-md transition duration-300 ease-in-out">
              Tarifs / Fonctionnement
            </a>
          </li>

          {/* Conditionally Render Connexion or Mon Compte */}
          {isLoggedIn ? (
            <>
              <li>
                {/* Conditionally render the correct dashboard link based on the user's role */}
                {userRole === 'AUTEUR' ? (
                  <a href="/mon-compte" className="hover:bg-[#26d43a] px-3 md:px-4 py-2 rounded-md transition duration-300 ease-in-out">
                    Mon Compte (Auteur)
                  </a>
                ) : (
                  <a href="/compte-diffuseur" className="hover:bg-[#26d43a] px-3 md:px-4 py-2 rounded-md transition duration-300 ease-in-out">
                    Mon Compte (Diffuseur)
                  </a>
                )}
              </li>
              {/* <li>
                <button onClick={handleLogout} className="bg-red-600 px-3 md:px-4 py-2 rounded-md text-white">
                  Déconnexion
                </button>
              </li> */}
            </>
          ) : (
            <li>
              <a href="/login" className="hover:bg-[#26d43a] px-3 md:px-4 py-2 rounded-md transition duration-300 ease-in-out">
                Connexion
              </a>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
