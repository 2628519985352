import { IoMdArrowDropright } from 'react-icons/io';
import Header1 from './Header1';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CompteD = () => {
  const [username, setUsername] = useState(''); // Store username dynamically
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login'); // Redirect to login if token is missing
          return;
        }

        // const response = await fetch('http://localhost:8000/api/user', {
          const response = await fetch('https://editions7.com/admin/public/api/user', {

          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsername(data.username);
          setUserRole(data.role); // Set the user's role
          setIsLoggedIn(true);
        } else {
          navigate('/login'); // Redirect to login if unauthorized
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        navigate('/login');
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleLogout = () => {
    // Clear localStorage and navigate to login page
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    <div className="bg-white min-h-screen">
      <Header1 />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {/* Sidebar */}
          <div className="bg-white shadow-md rounded-lg p-4">
            <ul className="space-y-4">
              <li className="group">
                <a
                  href="/compte-diffuseur"
                  className="font-bold text-gray-600 border border-green-500 rounded-md px-2 py-2 flex items-center"
                >
                  <span className="inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Tableau de bord
                </a>
              </li>
              <li className="group">
                <a
                  href="/livres"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes livres
                </a>
              </li>
              <li className="group">
                <a
                  href="/rechercher-auteurs"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Trouver des professionnels
                </a>
              </li>
              <li className="group">
                <a
                  href="/information-diffuseur"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes Informations
                </a>
              </li>
              <li className="group">
                <a
                  href="/devis-diffuseur"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Devis Impression / demande
                </a>
              </li>
              <li className="group">
                <a
                  href="/factures"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes factures
                </a>
              </li>
              <li className="group">
                <a
                  href="/book-sales"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Mes Ventes
                </a>
              </li>
              <li className="group">
                <a
                  href="/commandes"
                  className="font-bold text-gray-600 group-hover:border group-hover:border-green-500 group-hover:rounded-md group-hover:px-2 group-hover:py-2 flex items-center"
                >
                  <span className="hidden group-hover:inline-block text-green-500 mr-2">
                    <IoMdArrowDropright className="text-green-500 h-6 w-6" />
                  </span>
                  Commandes
                </a>
              </li>
            </ul>
          </div>

          {/* Main Content */}
          <div className="md:col-span-3">
            <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-400">
              <p className="text-lg mb-4">
                Bonjour <strong>{username || 'Utilisateur'}</strong> (vous n'êtes pas{' '}
                <strong>{username || 'Utilisateur'}</strong>?{' '}
                <span onClick={handleLogout} className="text-blue-600 cursor-pointer">
                  Déconnexion
                </span>
                )
              </p>
              <p className="text-sm mb-4">
                À partir du tableau de bord de votre compte, vous pouvez visualiser vos{' '}
                <a href="/recent-orders" className="text-blue-600">
                  commandes récentes
                </a>
                , gérer vos{' '}
                <a href="/delivery-addresses" className="text-blue-600">
                  adresses de livraison et de facturation
                </a>{' '}
                ainsi que{' '}
                <a href="/change-password" className="text-blue-600">
                  changer votre mot de passe
                </a>{' '}
                et{' '}
                <a href="/account-details" className="text-blue-600">
                  les détails de votre compte
                </a>
                .
              </p>
              <h2 className="text-md font-bold mb-4">
                L'objectif est de trouver des auteurs qui souhaitent passer par vous pour vendre leurs
                livres en points de ventes.
              </h2>
              <ol className="list-decimal pl-4 space-y-4">
                <li>
                  Trouver des auteurs dans la partie en haut du site : "Trouver des professionnels"
                </li>
                <li>Discuter avec les membres (infographistes, diffuseurs, correcteurs, ...)</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompteD;
