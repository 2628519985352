import { configureStore } from '@reduxjs/toolkit';
import inscriptionReducer from './InscriptionSlice'; // Ajustez le chemin selon votre projet

const store = configureStore({
  reducer: {
    inscription: inscriptionReducer, // Ajoutez vos reducers ici
  },
});

export default store;
