

import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import logo from '../diffuseurs/logo_editions7_transparent.png';
import chat from '../diffuseurs/chat.png';

const Header1 = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false); // For chat dropdown
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in and their role when the component mounts
    const userLoggedIn = localStorage.getItem('isLoggedIn');
    const role = localStorage.getItem('userRole');
    if (userLoggedIn) {
      setIsLoggedIn(true);
      setUserRole(role);
    }
  }, []);

  const handleLogout = () => {
    // Remove the login state and user role from localStorage and update state
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userRole');
    setIsLoggedIn(false);
    navigate('/login'); // Redirect to the login page after logout
  };

  const handleAccountRedirect = () => {
    if (userRole === 'author') {
      navigate('/mon-compte');
    } else if (userRole === 'diffuseur') {
      navigate('/compte-diffuseur');
    }
  };

  const toggleChatDropdown = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <header className="bg-[#00ae14] p-4 flex justify-between items-center">
      <div className="flex items-center">
        <a href="/Acceuil">
          <img src={logo} alt="Logo Editions 7" className="w-32 md:w-48 lg:w-[24rem] h-auto" />
        </a>
      </div>
      <div className="flex space-x-8 relative">
        {/* Chat button with dropdown */}
        <button
          className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg flex items-center"
          onClick={toggleChatDropdown}
        >
          <img src={chat} alt="Icon" className="w-5 h-5 mr-2" />
          CHAT
          <IoMdArrowDropdown className="ml-2" />
        </button>
        {isChatOpen && (
                   <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
            <ul className="py-1 text-gray-700">
              <li>
                <a
                  href="/mes-messages"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Mes messages
                </a>
              </li>
              <li>
                <a
                  href="/tous-les-membres"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Tous les membres
                </a>
              </li>
              {/* Conditionally show based on user role */}
              {userRole === 'AUTEUR' && (
                <li>
                  <a
                    href="/rechercher-diffuseurs"
                    className="block px-4 py-2 hover:bg-gray-100"
                  >
                     Diffuseurs
                  </a>
                </li>
              )}
              {userRole === 'DIFFUSEUR' && (
                <li>
                  <a
                    href="/rechercher-auteurs"
                    className="block px-4 py-2 hover:bg-gray-100"
                  >
                  Auteurs
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}

        {/* Conditional rendering based on login status */}
        {isLoggedIn ? (
          <>
        
            <button
              className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg"
              onClick={handleLogout}
            >
              Déconnexion
            </button>
          </>
        ) : (
          <a
            href="/login"
            className="bg-[#26d43a] text-white font-bold py-1 px-6 rounded-lg"
          >
            Connexion
          </a>
        )}
      </div>
    </header>
  );
};

export default Header1;


